<!-- 医生的患者 -->
<template>
	<div class="doctors_patient">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div>
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="150px" class="demo-form-inline">
								<el-form-item label="姓名" prop="statisticsName">
									<el-input clearable v-model="formInline.statisticsName " placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="产品厂家" prop="prodoctName">
									<el-select filterable  v-model="formInline.value" placeholder="请选择">
										<el-option
										  v-for="item in prodoctList"
										  :key="item.value"
										  :label="item.label"
										  :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="病例号" prop="prodoctName">
									<el-select filterable  v-model="formInline.value" placeholder="请选择">
										<el-option
										  v-for="item in prodoctList"
										  :key="item.value"
										  :label="item.label"
										  :value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="产品型号" prop="statisticsName">
									<el-input clearable v-model="formInline.statisticsName " placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item >
									<el-button type="primary" @click="querys">搜索</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button @click="EditPatientDetails">编辑病患详情</el-button>
									<el-button @click="ReviewRecords">查看随访记录</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table @selection-change="handleSelectionChange" ref="multipleTable" :header-cell-style="{background:'#eef1f6'}"
							 :data="tableData" style="width: 100%">,
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column align="center" prop="statisticsName" label="患者姓名">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="病历号">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="医生名称">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="起搏器厂家">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="起搏器型号">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="植入日期">
								</el-table-column>
								<el-table-column align="center" prop="statisticsPeriodHead" label="首次随访日期">
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//分页组件
			pages(current, size) {
				
			},
			//编辑病患详情
			EditPatientDetails(){
				this.$router.push({
				    path: '/PatientDetails'
				})
			},
			//查看随访记录
			ReviewRecords(){
				this.$router.push({
				    path: '/ReviewRecords'
				})
			},
			// 搜索
			querys() {

			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		},
		data() {
			return {
				//产品厂家
				prodoctList:[],
				// 选中数据
				multipleTable: [],
				//form表单查询
				formInline: {},
				//table列表
				tableData: [],
				//总条数
				total: 0,
			}
		},
		created() {},
		mounted() {}
	}
</script>

<style scoped>
</style>
